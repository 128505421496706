'use strict';

const GDDLHelper = require('./helpers/helper');
const sha256 = require('./helpers/sha256');

var atrack;

var PAGE_GROUP = {
    HOME: 'Home',
    CATEGORY_LANDING: 'Category Landing',
    PRODUCT_LISTING: 'Product Listing',
    PRODUCT_DETAIL: 'Product Detail',
    PRODUCT_SET: 'Product Set',
    SEARCH: 'Search',
    WISHLIST: 'Wishlist',
    CART: 'Cart',
    CHECKOUT: 'Checkout',
    ORDER_CONFIRM: 'Order Confirmation',
    ACCOUNT: 'Account',
    MY_TORFS: 'My Torfs',
    STORE: 'Store',
    CUSTOMER_SERVICE: 'Customer Service',
    OTHER: 'Other'
};

/**
 * Initialize the GDDL analytics tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

/**
 *
 * @returns {Object} Query string parameters
 */
function getQuerystringParams() {
    var result = {};
    var params = (window.location.search.split('?')[1] || '').split('&');
    for (var param in params) {
        if (params.hasOwnProperty(param)) {
            var paramParts = params[param].split('=');
            result[paramParts[0]] = decodeURIComponent(paramParts[1] || '');
        }
    }
    return result;
}

/**
 * Fetches the current pagegroup.
 *
 * @return {string} - The page group.
 */
function getPageGroup() {
    const $page = $('.page');

    if (!$page.length) return PAGE_GROUP.OTHER;

    const action = $page.data('action');
    const queryString = $page.data('querystring');

    switch (action) {
        case 'Home-Content':
            return PAGE_GROUP.HOME;
        case 'Search-Show':
            if (queryString.indexOf('cgid') >= 0) {
                if ($('.container.category-landing').length > 0) {
                    return PAGE_GROUP.CATEGORY_LANDING;
                }
                return PAGE_GROUP.PRODUCT_LISTING;
            }

            return PAGE_GROUP.SEARCH;
        case 'Product-Show':
        case 'Product-ShowInCategory':
            return $('.product-detail').data('producttype') === 'set' ? PAGE_GROUP.PRODUCT_SET : PAGE_GROUP.PRODUCT_DETAIL;
        case 'ProductLists-Detail':
            return PAGE_GROUP.WISHLIST;
        case 'Cart-Show':
            return PAGE_GROUP.CART;
        case 'Checkout-Begin':
        case 'Checkout-BeginGuest':
        case 'Checkout-Login':
            return PAGE_GROUP.CHECKOUT;
        case 'Order-Confirm':
            return PAGE_GROUP.ORDER_CONFIRM;
        case 'Register-Show':
        case 'Login-Show':
            return PAGE_GROUP.ACCOUNT;
        case 'Order-History':
        case 'Order-Details':
        case 'Order-ServiceDetails':
        case 'Address-List':
        case 'PaymentInstruments-List':
        case 'Stores-FavoriteStore':
            return PAGE_GROUP.MY_TORFS;
        case 'Stores-Find':
        case 'Stores-Detail':
            return PAGE_GROUP.STORE;
        case 'Account-ContactUs':
            return PAGE_GROUP.CUSTOMER_SERVICE;
        case 'Page-Show':
            return $('.pd-page-id').data('page-type') || PAGE_GROUP.OTHER;
        default:
            break;
    }

    if (action && action.indexOf('Account') >= 0) {
        return PAGE_GROUP.MY_TORFS;
    }

    return PAGE_GROUP.OTHER;
}

/**
 * Gets the userState and data. The user can be 'Logged In', 'Recognized' or 'Not Logged In'
 * @returns {Object} - User Status
 */
function getUserStatus() {
    const $authData = $('.js-auth-data');
    const $userData = $('.js-userData');
    const authenticated = $authData.data('authenticated');
    const registered = $authData.data('registered');
    let data = {
        id: $userData.data('userdata') ? $userData.data('userdata').id : null,
        email_sha256: $userData.data('userdata') ? $userData.data('userdata').email_sha256 : null,
        sscid: $userData.data('userdata') ? $userData.data('userdata').sscid : null,
        ssccid: $userData.data('userdata') ? $userData.data('userdata').ssccid : null
    };
    if (authenticated && registered) {
        data.status = 'Logged in';
    } else if (!authenticated && registered) {
        data.status = 'Recognized';
    } else data.status = 'Not logged in';
    return data;
}

/**
 * updates the userdata json of the .js-userData class
 * @param {string} emailsha256 - email of the user
 */
function changeUserData(emailsha256) {
    const $userData = $('.js-userData');
    $userData.data('userdata', { email_sha256: emailsha256 });
}

const createPageLoadData = () => {
    var userStatus = getUserStatus();
    var commerce = {
        url: window.location.href,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        domain: window.location.origin,
        referrer: document.referrer,
        title: window.document.title,
        path: window.location.pathname,
        params: getQuerystringParams(),
        hash: '',
        timestamp: new Date(performance.timing.domLoading),
        name: $('.page').data('pagename') || $('h1').first().text().trim() || '',
        type: getPageGroup(),
        breadcrumb: GDDLHelper.getBreadCrumbPath(),
        locale: `${$('html').attr('lang')}-${$('html').attr('country')}`,
        instanceType: $('.page').data('instancetype'),
        user: {
            clientid: GDDLHelper.getCookie('BrowserId'),
            customerid: userStatus.id,
            email_sha256: userStatus.email_sha256,
            status: userStatus.status,
            // ssccid in gddl is sscid from SC/CC, same for sscid in gddl is ssccid in SC/CC
            ssccid: userStatus.sscid,
            sscid: userStatus.ssccid
        }
    };

    return commerce;
};

const initPageLoad = () => {
    var ecommerceData = {
        event: 'page-impression',
        info: createPageLoadData()
    };

    atrack.trackEvent(ecommerceData);
};

const initTouchPointEvent = () => {
    // This event will be triggerd by the SFSC Live Chat script
    $(document).on('email_sha256:validateChatbotEmail', function (e) {
        const email = e.detail && e.detail.email;
        if (email && email.length <= 4) return;

        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(email)) return;

        $(document).trigger('idTouchPoint:fire', { email_sha256: sha256(email), sscid: '', touchpointName: 'chat' });
    });

    /**
     * data needs the following objects:
     * data.email_sha256: the customer email from cc
     * data.customerid: the customer id in cc
     * data.name: the touchpointname of the user event ( guestCheckout, registration, newsLetterSubscription, chat, contactFrom)
     */
    $(document).on('idTouchPoint:fire', function (e, data) {
        if (data.email_sha256) changeUserData(data.email_sha256);
        atrack.trackEvent({
            event: 'touchpoint-id',
            info: {
                email_sha256: data.email_sha256,
                sscid: data.sscid,
                name: data.touchpointName
            }
        });
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('SubscriberKey')) {
        const SubscriberKey = urlParams.get('SubscriberKey');
        $(document).trigger('idTouchPoint:fire', { email_sha256: '', sscid: SubscriberKey, touchpointName: 'email' });
    }
};

const init = () => {
    initAnalyticsTracker();
    initPageLoad();
    initTouchPointEvent();
};

$(() => {
    init();
});
